import * as logger from "services/logger";

import React, { useEffect } from "react";

import { Card } from "antd";
import { authProvider } from "infra/msal/authProvider";
import marca from "res/img/marca.svg";
import styled from "styled-components";

function ErrorFallback({ error }: any) {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <Container>
      <StyledCard>
        <Title>Ops...</Title>
        <Title>Ocorreu um erro inesperado</Title>
        <Divider />
        <Subtitle>O que você pode fazer?</Subtitle>
        <Subtitle>
          Você pode abrir um IR clicando <strong><a href="https://se.amaggi.com.br/softexpert">aqui</a></strong>{" "}
          e se necessário envie um e-mail para <strong><a href="mailto:ti.agro@amaggi.com.br">ti.agro@amaggi.com.br</a></strong>{" "}
          informando o IR e dando mais detalhes do problema.
        </Subtitle>
      </StyledCard>

      <MarcaDAgua aria-label="tracecotton-logo" src={marca} />
    </Container>
  );
}

const StyledCard = styled(Card)`
  padding: 2em 1em;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  height: 1.25em;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: 500;
  color: #12a557;
`;

const Subtitle = styled.h3`
  text-align: center;
  font-weight: 500;
  color: #888888;
`;

const MarcaDAgua = styled.img`
  position: absolute;
  bottom: 1em;
`;

export const onError = async (
  error: Error,
  info: { componentStack: string }
) => {
  const { account } = (await authProvider.getAccountInfo()) || {};

  logger.log("error", "Crash error", {
    error,
    info,
    user_email: account?.userName,
  });
};

export function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export default ErrorFallback;
