export enum URLS {
  LOGIN = "/auth/login",
  REFRESH_TOKEN = "/auth/refresh-token",
  CONTRATOS = "/contratos",
  BUSCAR_CONTRATOS = "/contratos/search",
  SAFRAS = "/safras",
  CLIENTES = "/clientes",
  COMERCIAL = "/comercial",
  CLASSIFICACOES = "/classificacoes",
  DASHBOARD_CONTRATOS = "/dashboards/contratos/projecao/vendas",
  TOTAL_CONTRATOS = "/contratos/total",
  TOTAL_PROJECOES = "/projecoes/total",
  TOTAL_AVENDER = "/contratos/vendas/total",
  TAKE_UP = "/takeUp",
  TAKE_UPS = "/take-ups",
  TAKE_UPS_LOTES = "comercial/take-ups/{takeUpId}/alterar-status-lotes",
  ROLOS_FARDOES = "/rolos-fardos",
  FARDINHOS = "/fardinhos",
  ALGODOEIRA = "/algodoeira",
  ALGODOEIRAS = "/algodoeiras",
  SESSOES = "/sessoes",
  SESSAO_DETALHES = "/sessoes/:sessaoId",
  SESSAO_DETALHES_PENDENCIAS = "/sessoes/{sessaoId}/detalhes",
  SESSAO_DETALHES_INFO = "/sessoes/{sessaoId}/informacoes",
  SESSAO_DETALHES_STATUS_FINALIZACAO = "/sessoes/{sessaoId}/status-finalizacao",
  SESSAO_INFO = "/sessoes/informacoes",
  REENVIAR_SESSAO = "/sessoes/reenviar",
  PARADAS = "/sessoes/paradas",
  URL_PARADAS = "/paradas",
  PARADAS_MOTIVOS = "/paradas/motivos",
  MOTIVOS = "/sessoes/paradas/motivos",
  SESSOES_DISPONIVEIS = "/paradas/sessoes-abertas",
  EQUIPAMENTOS = "/paradas/equipamentos",
  PARADAS_COMPONENTES = "/paradas/componentes",
  TIPOS = "/paradas/tipo-elemento",
  MAQUINAS = "/maquinas",
  CONFIGURACOES_MAQUINAS = "/maquinas/configuracoes",
  CATEGORIAS_MAQUINAS = "/maquinas/categorias",
  FAZENDAS = "/regioes/fazendas",
  SESSOES_EVENTOS = "/sessoes/{sessao_id}/eventos",
  SESSION_INFORMATION_PRODUCTIVITY = "/sessoes/informacoes-rendimento",
  SESSION_STOP_INFORMATION = "/sessoes/paradas/informacoes",
  PERMISSOES = "/permissoes",
  USUARIO = "/usuario",
  ATIVAR_USUARIO = "/usuario/ativacao",
  USER_INFO = "/user_info",
  COMPARATIVO_RENDIMENTO = "/sessoes/comparativo-rendimento",
  PROFILE = "/perfil",
  PROFILE_PERMISSION = "/perfil/{perfil_id}/permissoes",
  PERMISSION = "/permissao",
  USER_PERMISSION_FARM = "/usuario/fazenda",
  USER_PERMISSION_MACHINE = "usuario/maquina",
  LOCAIS_ARMAZENAMENTO = "/local-armazenamento",
  LISTA_LOCAIS_ARMAZENAMENTO = "/local-armazenamento/listar",
  PAINEL_INTEGRACAO = "/painel-integracao",
  FILA_INTEGRACAO = "/fila-integracao",
  WEBHOOKS_INTEGRACAO = "/webhooks",
  PARAMETROS_ALGODOEIRA = "/configuracoes/parametros-algodoeira",
  REENVIA_ROLO = "rolos-fardos/marcar-para-reenvio",
  ESTORNA_ROLO = "rolos-fardos/{id}/estornar",
  REENVIA_TODOS_ROLOS = "rolos-fardos/marcar-todos-para-reenvio",
  QUANTIDADE_SOLICITACAO = "/take-ups/solicitacao-edicao/total",
  RECUSAR_SOLICITACAO = "/comercial/take-ups/solicitacao-edicao/recusar",
  APROVAR_SOLICITACAO = "/comercial/take-ups/solicitacao-edicao/aprovar",
  SOLICITACAO_EDICAO = "/comercial/take-ups/solicitacao-edicao",
  ORDENS_COLHEITA = "/cadastros/ordens-colheita",
  CADASTRO_EQUIPES = "/cadastros/equipes",
  CADASTRO_LOCAIS_ARMAZENAMENTO = "/cadastros/locais-armazenamento",
  CADASTRO_ALGODOEIRA = "/cadastros/algodoeiras",
  CADASTRO_VARIEDADES = "/cadastros/variedades",
  CADASTRO_OCUPACOES = "/cadastros/ocupacoes",
  CADASTRO_MOTIVOS_PARADAS = "/cadastros/motivos-paradas",
  CADASTRO_PROJECAO_PRODUCAO = "/cadastros/projecao-producao",
  PROJECAO_PRODUCAO = "/projecoesfazendas",
  MENU_CADASTROS = "/cadastros/basicos",
  CADASTRO_PARAMETROS = "/parametros-integracao",
  MENU_CONFIGURACOES = "/configuracoes",
  CONSULTA_PARAMETROS_INTEGRACAO = "/parametros-integracao/paginado",
  CONSULTA_LOCAIS_ARMAZENAMENTO = "/armazenamento/locais",
  CONSULTA_EQUIPES = "/equipes",
  CONSULTA_OCUPACOES = "/ocupacoes",
  MENU_IMPORTACAO = "/importacao",
  IMPORTACAO_HVI = "/importacao/hvi",
  UPLOAD_HVI = "/unicotton/hvi/upload",
  IMPORTACAO_CLASSIFICACAO = "/importacao/classificacao-visual",
  UPLOAD_CLASSIFICACAO = "/unicotton/classificacao/upload",
  LOTES = "/lotes/Obter",
  VISUALIZAR_LOTES = "/lotes/{lote_id}",
  FARM_LOT_INFORMATION = "/lotes/dashboard",
  REENVIAR_LOTE = "/lotes/reenviar",
  REENVIAR_FARDINHO = "/lotes/reenviar-fardinho",
  FECHAR_LOTE = "/lotes/{lote_id}/fechar",
  HOMOLOGAR_LOTE = "/lotes/homologar-fardinhos",
  HOMOLOGAR_FARDINHO = "/lotes/homologar-fardinho",
  LOTS_FARM_AUTHORIZED = "/fazendas/permitidas",
  LOTS_FARM_MACHINE_CATEGORIES = "/maquinas/categorias-por-fazenda",
  CADASTRO_TRANSPORTADOR = "/transportadores",
  MOTORISTAS = "/motoristas",
  VEICULOS = "/veiculos",
  PORTAL_BALANCA_INDEX = "/portal-balanca",
  CARGAS = "/cargas",
  ESTORNA_SESSAO = "/sessoes/{id}/estornar",
  DELETAR_PARAMETROS_INTEGRACAO = "/parametros-integracao",
  CARGA = "/cargas",
  CARGA_DETALHES = "/cargas/detalhes",
  CARGA_EXLUSAO_ROLO = "/cargas/{id}/excluir-rolos",
  LEITURA = "/cargas/leitura",
  CONSULTA_TRANSPORTADOR_POR_PLACA = "/transportadores/obter-por-placa",
  ORDENS_COLHEITA_POR_FAZENDA = "ordens-colheita/listar-por-fazenda",
  DETALHES_ORDEM_COLHEITA = "/ordens-colheita",
  LISTA_SINISTROS = "/sinistros",
  LISTA_CONTAMIANTES = "/contaminantes",
  CADASTRO_ROLO = "/rolos-fardos",
  CADASTRO_CARGA_MANUAL = "/cargas/manual",
  HUB_LEITURA_TEMPO_REAL = "hubs/portal-rfid",
  ADICIONAR_ROLO_CARGA = "/cargas/{cargaId}/adicionar-rolo",
  PERMISSOES_USUARIO_NOVO = "/usuario/permissoes-por-fazenda",
  CONFERENCIA_PATIO = "/patio/conferencia",
  CONFERENCIA_PATIO_DASHBOARD = "/patios/conferencia/dashboard",
  CONFERENCIA_PATIO_LISTAGEM_TALHOES = "/patios/conferencia/talhoes",
  CONFERENCIA_BLOCOS = "/relatorios/conferencia-bloco",
  CONFERENCIA_PATIO_BENEFICIADOS = "/relatorios/patio-beneficiados",
  RELATORIO_CONFERENCIA_PATIO = "/relatorios/conferencia-patio",
  CONFERENCIA_SESSAO = "/relatorios/conferencia-sessao",
  BENEFICIAMENTO = "/beneficiamento",
  RELATORIO_BENEFICIAMENTO = "/relatorios/beneficiamento/detalhes",
  CONFERENCIA_ROLOS = "/relatorios/conferencia-rolos",
  VOLUME_ESTOQUE = "/relatorios/volume-estoque",
  RELATORIO_VOLUME_ESTOQUE = "/relatorios/volume-estoque-volume-embarcado",
  MENU_RELATORIOS = "/relatorios",
  FARDINHOS_NAO_CLASSIFICADOS = "/fardinhos-nao-classificados",
  ENTRADA_PRODUCAO = "/entrada-producao",
  RELATORIO_ENTRADA_PRODUCAO = "/relatorios/entrada-producao",
  DASHBOARD_FARDINHOS_NAO_CLASSIFICADOS = "/dashboards/classificacao-algodao",
  CARGA_DETALHES_ROLO_EXCLUIDO = "/carga-rolos/detalhes/rolo-excluido",
  CARGA_DETALHES_DESFAZER_ROLO_EXCLUIDO = "/carga-rolos/desfazer-exclusao/{cargaRoloRemovedId}",
  SESSOES_DETALHES_ROLO = "/sessoes/:sessaoId/rolos",
  SESSAO_RENDIMENTO_MINIDASHBOARD = "/sessoes/{sessaoId}/informacoes-rendimento",
  SESSAO_MOVIMENTAR_SESSAO = "/sessoes/:sessaoId/movimentar",
  SESSAO_MOVIMENTAR = "/sessoes/:sessaoId/movimentar/:sessaoDestinoId",
  SESSAO_FINALIZAR = "/sessoes/{sessaoId}/finalizar",
  SESSAO_HOMOLOGAR = "/sessoes/{sessaoId}/homologar",
  SESSAO_VAZIA_HOMOLOGAR = "/sessoes/{sessaoId}/homologar-sessao-vazia",
  SESSAO_REABRIR = "/sessoes/{sessaoId}/reabrir",
  SESSAO_ROLOS_DIVERGENTES = "/sessoes/{sessaoId}/rolos-divergentes",
  CARGA_REABRIR = "/cargas/{cargaId}/reabrir",
  SESSAO_ALTERAR = "/sessoes/{sessaoId}",
  SESSAO_ROLOS_DIVERGENTES_GERAL = "/sessoes/rolos-divergentes",
  RELATORIO_CONFERENCIA_CAMPO = "/relatorios/conferencia-campo",
  FARDINHOS_DO_ROLO = "/fardinhos/{roloId}",
  RELATORIO_PADROES_HVI = "/relatorios/padroes-hvi",
  FAZENDAS_PERMITIDAS = "/fazendas/permitidas",
  MAQUINAS_PERMITIDAS = "/maquinas/permitidas",
  COMERCIAL_GRUPOS_CLIENTE = "/comercial",
  WEBHOOKS_INTEGRACAO_DASHBOARD = "/webhooks/dashboard",
  WEBHOOKS_EVENTOS = "/webhooks/eventos",
  COMERCIAL_GRUPOS_CLIENTE_DETALHES = "/comercial/{grupoClienteId}",
  COMERCIAL_PERIODO_DETALHADO = "/comercial/contratos/{contratoId}/periodos",
  COMERCIAL_GRUPOS_CLIENTE_DETALHES_CONTRATOS = "/comercial/{grupoClienteId}/detalhes",
  UPLOAD_ACTS = "/acts/importacao",
  TAKE_UP_DETALHES = "/comercial/take-ups/:takeUpId/detalhes",
  COMERCIAL_GRUPO_CLIENTE_TAKEUPS = "/comercial/cliente/{grupoClienteId}/takeups",
  COMERCIAL_CONTRATO_TAKEUPS = "/comercial/contrato/{contratoId}/takeups",
  COMERCIAL_CONTRATO_TAKEUPS_RELATORIO_PDF = "/comercial/take-ups/{takeUpId}/relatorio-pdf",
  COMERCIAL_CONTRATO_TAKEUPS_RELATORIO_XLS = "/comercial/take-ups/{takeUpId}/relatorio-xls",
  COMERCIAL_CONTRATO_TAKEUPS_ENVIAR_EMAIL = "/comercial/take-ups/enviar-email",
  COMERCIAL_CONTRATO_TAKEUPS_EXCLUIR = "/comercial/take-ups/{takeUpId}",
  COMERCIAL_CONTRATO_TAKEUPS_LIBERA_EDICAO = "/comercial/take-ups/{takeUpId}/liberar-edicao",
  COMERCIAL_CONTRATOS_CLIENTES = "/comercial/contratos",
  COMERCIAL_CADASTRO_CARREGAMENTO = "/carregamentos",
  COMERCIAL_EDICAO_CARREGAMENTO = "/carregamentos/{carregamentoId}",
  COMERCIAL_HOMOLOGAR_CARREGAMENTO = "/carregamentos/{carregamentoId}/homologar",
  COMERCIAL_REABRIR_CARREGAMENTO = "/carregamentos/{carregamentoId}/reabrir",
  COMERCIAL_CADASTRO_CARREGAMENTO_LOTES_RESERVADOS = "/comercial/instrucao-embarque/{instrucaoEmbarqueId}/lotes-reservados",
  GRUPOS_EMAIL = "/grupo-emails",
  TAKE_UP_OBSERVACOES = "/take-ups/observacoes",
  ESTOQUE_COMERCIAL = "/comercial/visualizar-estoque",
  ESTOQUE_RELATORIOS = "/relatorios/estoque",
  CONTRATO_DETALHES = "/contratos/{contratoId}",
  RELATORIOS_CALENDARIO_TAKEUPS_RELATORIO_PDF = "/relatorios/take-ups/calendario/{takeUpId}/relatorio-pdf",
  RELATORIOS_CALENDARIO_TAKEUPS_RELATORIO_XLS = "/relatorios/take-ups/calendario/{takeUpId}/relatorio-xls",
  DADOS_CABECALHO_CARREGAMENTO = "/comercial/{grupoClienteId}/carregamentos/cabecalho",
  DADOS_CONTRATOS_CARREGAMENTO = "/comercial/{grupoClienteId}/carregamentos/ordens-embarque",
  ORDENS_EMBARQUE = "/comercial/{contratoId}/carregamentos/ordens-embarque",
  EMAILS_BENEFICIAMENTO = "/email-beneficiamento-diario",
  EMAILS_BENEFICIAMENTO_STATUS = "/email-beneficiamento-diario/status",
  EMAILS_BENEFICIAMENTO_ALTERAR_STATUS = "/email-beneficiamento-diario/alterar-status-parametro",
  CARREGAMENTO_DETALHES_LOTE = "/carregamentos/lote-reservado/{carregamentoPlanejadoId}",
  COMERCIAL_TAKEUP = "/comercial/take-ups/{takeUpId}",
  COMERCIAL_TAKEUP_LOTES_AGENDADO = "/comercial/take-ups/lotes-agendamento",
  COMERCIAL_DETALHES_CARREGAMENTO = "/carregamentos/{carregamentoId}/edicao",
  COMERCIAL_CARREGAMENTO_INSERT_PESAGEM_MANUAL = '/carregamentos/{carregamentoId}/pesagem-entrada',
  HUB_LEITURA_TEMPO_REAL_PORTARIA_AUTONOMA = "hubs/portaria-autonoma",
  LEITURA_EM_ANDAMENTO = "/cargas/patio/{patioId}/existe-leitura-em-andamento",
  COMERCIAL_UPDATE_VEICULO = "/comercial/veiculos/{placa}",
  COMERCIAL_INSTRUCOES_EMBARQUE = "/comercial/instrucoes-embarques",
  LEITURA_EM_ANDAMENTO_ROLOS_LIDOS = "cargas/patio/{patioId}/acompanhar-leitura-em-andamento",
  ROLOS_ORDEM_COLHEITA = "/ordens-colheita/{idOrdem}/rolos",
  MOVIMENTACAO_ROLOS_ORDEM_COLHEITA = "/ordens-colheita/movimentar",
  GRUPO_VARIEDADES = "/grupo-variedade/{safraId}/{fazendaId}",
  TALHOES="/talhao/{safraId}/{fazendaId}",
  CARREGAMENTOS = "/carregamentos",
  OPERACIONAL_CARREGAMENTOS = "/carregamentos",
  OPERACIONAL_EDICAO_CARREGAMENTO = "/carregamentos/{carregamentoId}",
  OPERACIONAL_INSTRUCOES_EMBARQUES = "/carregamentos/instrucoes-embarques",
  OPERACIONAL_CADASTRO_CARREGAMENTO_LOTES_RESERVADOS = "/carregamentos/lotes-reservados",
  COMERCIAL_CANCELAR_CARREGAMENTO = "/carregamentos/{carregamentoId}/cancelar",
  COMERCIAL_VINCULAR_DADOS_CARREGAMENTO = "/carregamentos/vincular-dados",
  COMERCIAL_CANCELAR_E_CRIAR_NOVO_CARREGAMENTO = "/carregamentos/{carregamentoId}/cancelar-criar-novo",
  FARDINHOS_CARREGADOS = "/carregamentos/{carregamentoId}/fardinhos-carregados",
  ESTORNAR_FARDINHOS_CARREGADOS = "carregamentos/{carregamentoId}/estornar-carregamento",
  ESTORNAR_FARDINHO_PARA_ADICIONAR = "carregamentos/estorno/fardinho-para-adicionar/{codigoSai}",
  CANCELAR_ESTORNO_FARDINHOS = "carregamentos/{carregamentoId}/cancelar-estorno",
  REENVIAR_ESTORNO_FARDINHOS = "carregamentos/{carregamentoId}/reenviar-estorno",
  FARDINHOS_NO_PORTO = "/comercial/fardinhos-no-porto",
  INSTRUCOES_EMBARQUE_DISPONIVEIS = "/comercial/fardinhos-no-porto/instrucoes-embarques-disponiveis",
  MARCAR_FARDINHO_NO_PORTO_COMO_VENDIDO = "/comercial/fardinhos-no-porto/{fardinhoPortoId}/marcar-como-vendido",
  TROCA_INSTRUCOES_EMBARQUE = "/comercial/fardinhos-no-porto/{fardinhoPortoId}/trocar-instrucao-embarque",
  COMERCIAL_FARDINHOS_IRREGULARES = "/comercial/instrucoes-embarques/{ordemEmbarqueId}/fardinhos-irregulares",
  COMERCIAL_FARDINHOS_LOTE_INSTRUCAO_EMBARQUE = "/comercial/instrucao-embarque/lote-reservado/{loteId}/{ordemVendaId}",
  DELETE_CARREGAMENTO = "/carregamentos/{carregamentoId}",
  CARREGAMENTOS_DISPONIVEIS_COM_MESMAS_CARACTERISTICAS = "/carregamentos/disponiveis-com-mesmas-caracteristicas?CarregamentoId={carregamentoId}",
  RELATORIO_HVI_XLS = "/take-ups/{takeUpId}/relatorio-hvi-xls",
  COMERCIAL_CARREGAMENTO_LOTES_RESERVADOS = "comercial/carregamentos/lote-reservado/{carregamentoPlanejadoId}",
  COMERCIAL_INSTRUCAO_EMBARQUE_LOTES_DETALHE = "comercial/instrucao-embarque/lote-reservado/{loteId}/{ordemVendaId}",
  OPERACIONAL_INSTRUCAO_EMBARQUE_LOTES_DETALHE = "carregamentos/instrucao-embarque/lote-reservado/{loteId}/{ordemVendaId}",
}
